/* eslint-disable import/prefer-default-export */
import { useApolloClient } from '@apollo/client';
import { bulkSkuMagentoLabel } from '@core_modules/catalog/services/graphql/productSchema';

export const useBulkQueryWeltpixel = (productsSku) => {
    const client = useApolloClient();
    const [dataBulk, setDataBulk] = React.useState([]);

    const callQuery = async () => {
        try {
            const { data } = await client.query({
                query: bulkSkuMagentoLabel,
                variables: {
                    bulkSku: productsSku,
                },
                context: {
                    request: 'useTimestampParam',
                },
            });

            const resultArrayData = data?.products?.items || [];
            setDataBulk(resultArrayData);
        } catch (e) {
            setDataBulk([]);
        }
    };

    React.useEffect(() => {
        if (productsSku && productsSku.length > 0) {
            callQuery();
        }
    }, [productsSku]);

    return dataBulk;
};
